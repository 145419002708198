<template>
  <div>
    <a-row style="padding-right: 10px">
      <a-col
        class="content-row"
        v-for="(item, index) in theList()"
        :key="index"
        :span="24"
      >
        <h3>{{ index + 1 }} - {{ item.name }}</h3>
        <div class="list">
          <a-row class="infos" :gutter="20">
            <a-col :span="3">
              <span class="label">Checkin:</span>
              {{ item.checkin ? formatMultiDates(item.checkin) : "" }}
            </a-col>

            <a-col :span="3">
              <span class="label">Checkout:</span>
              {{ item.checkin ? formatMultiDates(item.checkout) : "" }}
            </a-col>

            <a-col :span="2">
              <span class="label"> Diarias:</span>
              {{ item.days }}
            </a-col>

            <a-col :span="8">
              <span class="label"> Regime Alimentar:</span>
              {{ item.diet }}
            </a-col>

            <a-col :span="4">
              <span class="label"> Localizador:</span>
              {{ item.locator }}
            </a-col>

            <a-col class="mt-10 mb-5" :span="24"><h4>QUARTOS</h4> </a-col>
          </a-row>

          <a-row
            v-for="(room, index) in item.rooms"
            :key="index"
            class="rooms mb-10"
            :gutter="20"
          >
            <a-col :span="10">
              <span class="label">Acomodação {{ index + 1 }}:</span>
              {{ room.name }}
            </a-col>

            <a-col class="travellers" :span="14">
              <span class="label">Hóspedes</span>
              <font
                class="font"
                v-for="(traveller, index) in room.travellers"
                :key="index"
              >
                {{ traveller.name }}
              </font>
            </a-col>
          </a-row>

          <!-- <pre> {{ theList() }} </pre> -->
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
//import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings.js";

export default {
  props: {
    tempContract: Object,
    sale: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  methods: {
    thePassenger(id) {
      let travellers = JSON.parse(this.tempContract.travellers_rows);
      let theTraveller = {};

      travellers.forEach((traveller) => {
        if (traveller.id == id) {
          theTraveller.name = `${
            this.tempContract[`traveller_${traveller.id}_first_name`]
          } ${this.tempContract[`traveller_${traveller.id}_last_name`]}`;

          theTraveller.birthday = `${
            this.tempContract[`traveller_${traveller.id}_birthday`]
          }`;

          if (this.tempContract[`traveller_${traveller.id}_gender`]) {
            theTraveller.gender = `${
              this.tempContract[`traveller_${traveller.id}_gender`]
            }`;
          }
        }
      });

      return theTraveller;
    },
    theList() {
      let arr = [];
      let contractedServices = JSON.parse(
        this.tempContract.contracted_services
      );

      if (contractedServices.includes("hotel")) {
        JSON.parse(this.tempContract["hotel_rows"]).forEach((hotel) => {
          let allRooms = [];

          hotel.accommodation.forEach((room) => {
            let allTravellers = [];

            room.travellers.forEach((traveller) => {
              allTravellers.push(
                this.thePassenger(
                  this.tempContract[
                    `hotel_${hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`
                  ]
                )
              );
            });

            allRooms.push({
              name: `${
                this.tempContract[`hotel_${hotel.id}_accommodation_${room.id}`]
              } (${
                this.tempContract[
                  `hotel_${hotel.id}_accommodation_${room.id}_type`
                ]
              })`,
              travellers: allTravellers,
            });
          });

          arr.push({
            name: this.tempContract[`hotel_${hotel.id}_name`],
            checkin: this.tempContract[`hotel_${hotel.id}_checkin`],
            checkout: this.tempContract[`hotel_${hotel.id}_checkout`],
            days: this.tempContract[`hotel_${hotel.id}_rates`],
            nights: this.tempContract[`hotel_${hotel.id}_nights`],
            diet: this.tempContract[`hotel_${hotel.id}_diet`],
            locator: this.tempContract[`hotel_${hotel.id}_locator`],
            rooms: allRooms,
          });
        });
      }

      return arr;
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  padding: 10px
  border: 1px solid #ededed !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 12px
  font-weight: 600
  .label
    display: block
    font-size: 10px
    font-weight: 700
  h4
    font-size: 10px !important
    font-weight: 600
    color: #f142aa
  .travellers
    .font
      font-weight: 600
      border-radius: 4px
      font-size: 12px
      background: #eee
      padding: 1px 5px 2px
      margin: 4px 4px 0px 0
      text-transform: uppercase
      word-break: break-all
      float: left
  .p-row.sub
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 22px
  .p-row.no-dot
    border: 0
    .name
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name.hotel
      color: #00c100
      font-weight: 600
    .name.service
      color: #ff8000
      font-weight: 600
    .name.flight
      font-weight: 600
      color: #1890ff
  .p-row
    font-size: 12px
    border-bottom: 2px dotted #ccc
    margin: 4px
    .val
      top: 8px
      background: #FFF
      padding: 0 0px 0 5px
    .ico
      width: 25px
      text-align: center
      display: inline-block
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 0px
      max-width: 220px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      .num
        font-weight: 700
  padding: 10px
  .t1
    color: #00cd6b
  .t2
    color: #ff4545
  .t3
    color: #ff9a3e
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
